import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import logo from "../assets/logo.png";
import phone from "../assets/name_phone.png";

function Navbar() {
  return (
    <nav className="navbar">
      <div className="top">
        <Link to="/" className="image-container">
          <img src={logo} width="100%" height="100%" alt="logo" />
        </Link>
        <div className="nav">
          <Link to="/about">
            <h3>About Us</h3>
          </Link>
          <Link to="/services">
            <h3>Our Services</h3>
          </Link>
          {/* <Link to="/careers">
            <h3>Careers</h3>
          </Link> */}
          <Link to="/contact">
            <h3>Contact Us</h3>
          </Link>
        </div>
        {/* <img src={phone} width="257" height="100" alt="contact" /> */}
      </div>
    </nav>
  );
}

export default Navbar;

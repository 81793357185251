import React from "react";
import map from "../assets/map.png";
import { Routes, Route, Link } from "react-router-dom";
import p1 from "../assets/providers/01.jpeg";
import p2 from "../assets/providers/02.jpeg";
import p3 from "../assets/providers/03.jpeg";
import p4 from "../assets/providers/04.jpeg";
import p5 from "../assets/providers/05.jpeg";
import p6 from "../assets/providers/06.jpeg";
import p7 from "../assets/providers/07.jpeg";
import p8 from "../assets/providers/08.jpeg";
import p9 from "../assets/providers/09.jpeg";
import p10 from "../assets/providers/10.jpeg";
import s1 from "../assets/slideshow/001.jpeg";
import s2 from "../assets/slideshow/002.jpeg";
import s3 from "../assets/slideshow/003.jpeg";
import s4 from "../assets/slideshow/004.jpeg";
import s5 from "../assets/slideshow/005.jpeg";
import s6 from "../assets/slideshow/006.jpeg";
import s7 from "../assets/slideshow/007.jpeg";
import s8 from "../assets/slideshow/008.jpeg";
import s9 from "../assets/slideshow/009.jpeg";
import s10 from "../assets/slideshow/010.jpeg";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

function Home() {
  return (
    <div className="home">
      <div className="slide-background">
        <div className="slide-container">
          <Fade duration={1500} arrows={false}>
            <div className="each-fade">
              <img src={s1} alt="slide1" />
            </div>
            <div className="each-fade">
              <img src={s2} alt="slide2" />
            </div>
            <div className="each-fade">
              <img src={s3} alt="slide3" />
            </div>
            <div className="each-fade">
              <img src={s4} alt="slide4" />
            </div>
            <div className="each-fade">
              <img src={s5} alt="slide5" />
            </div>
            <div className="each-fade">
              <img src={s6} alt="slide6" />
            </div>
            <div className="each-fade">
              <img src={s7} alt="slide7" />
            </div>
            <div className="each-fade">
              <img src={s8} alt="slide8" />
            </div>
            <div className="each-fade">
              <img src={s9} alt="slide9" />
            </div>
            <div className="each-fade">
              <img src={s10} alt="slide10" />
            </div>
          </Fade>
        </div>
      </div>
      <div className="content">
        <div className="column">
          <h2>Welcome</h2>
          <p>
            Highlander Xpress is a fully licensed and bonded transportation
            company founded in July 2003.
            <br></br>
            <br></br>
            With a home base in South Florida, we serve customers throughout the
            continental United States and place a strong emphasis on excellent
            customer service and guaranteed satisfaction.
          </p>
          <h3>States we serve:</h3>
          <img src={map} width="260" height="160" alt="map" />
          <p>
            We offer 24-hour dispatch services and transport your freight on
            new, well-maintained air-ride trucks and 53" trailers.
            <br></br>
            <br></br>
            Our team of professional drivers has one goal in mind: the
            reliability and safety of your shipment in our hands.
          </p>
          <Link to="/about">Read more about Highlander Xpress...</Link>
        </div>
        <div className="column">
          <h2>Services</h2>
          <p>
            At Highlander Xpress, we offer a range of services to help meet your
            demands. Along with unparalleled local service in South Florida, we
            offer long haul shipments with expedited services.
            <br></br>
            <br></br>
            We also offer transloading services using our operations network to
            get your freight where it needs to go.
            <br></br>
            <br></br>
            We can provide door-to-door delivery with tracking to check on your
            freight and verify delivery.
            <br></br>
            <br></br>
            We also offer a range of other services, including transportation
            logistics, heavy haul operations and dedicated fleets. We specialize
            in expedited team service as well for time sensitive shipments and
            the fastest delivery possible.
            <br></br>
            <br></br>
            We'll also work with you to set a plan in place to control fuel
            costs, no matter the size of your shipment.
          </p>
          <Link to="/services">Read more about our services...</Link>
        </div>
        <div className="column">
          <h2>Our Promise to You</h2>
          <ul className="main-list">
            <li>
              Highlander Xpress understands the importance of timely and safe
              deliveries.
            </li>
            <br></br>
            <li>
              We provide you with accurate and clear explanations about our
              services and charges and use only the best, highly trained drivers
              and staff.
            </li>
            <br></br>
            <li>
              With 24-hour dispatch, we will make sure your shipment gets to its
              destination on time.
            </li>
            <br></br>
            <li>
              We transport your freight using new and well-maintained air rides
              and 53" trailers that protect even fragile cargo.
            </li>
            <br></br>
            <li>
              We guarantee the safety of your freight in our hands and strive to
              minimize every chance of damage during every transportation.
            </li>
          </ul>
        </div>
      </div>
      <div className="providers">
        <h2>We Proudly Provide For:</h2>
        <div className="provider-image">
          <img src={p1} alt="provider1" />
          <img src={p2} alt="provider2" />
          <img src={p3} alt="provider3" />
          <img src={p4} alt="provider4" />
          <img src={p5} alt="provider5" />
          <img src={p6} alt="provider6" />
          <img src={p7} alt="provider7" />
          <img src={p8} alt="provider8" />
          <img src={p9} alt="provider9" />
          <img src={p10} alt="provider10" />
        </div>
      </div>
    </div>
  );
}

export default Home;

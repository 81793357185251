import "./App.css";
import React from "react";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import About from "./components/About";
import Services from "./components/Services";
import Careers from "./components/Careers";
import Contact from "./components/Contact";
import Resources from "./components/Resources";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Navbar sticky="top" />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/footer" element={<Footer />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import logo from "../assets/logo.png";
import phone from "../assets/name_phone.png";
import map from "../assets/map.png";
import truck from "../assets/about.jpeg";

function About() {
  return (
    <div className="about">
      <div className="content">
        <div className="content-left">
          <h2>About Us</h2>
          <p>
            Highlander Xpress is a bonded and licensed transportation company
            based in South Florida. We were founded in July 2003 and serve
            customers throughout the continental United States. Above all else,
            we believe in guaranteed customer satisfaction and service.
            <br></br>
            <br></br>
            We offer 24-hour dispatch and transport all of your freight on new
            air-ride trucks and 53" trailers that are well-maintained to protect
            your most delicate cargo. Our team of professional drivers have a
            single goal: safe and reliable shipping of your freight while it's
            in our hands.
          </p>
          <img src={truck} width="628" height="244" alt="04"></img>
          <h3>Services at Highlander Xpress</h3>
          <p>
            We offer a complete range of services at Highlander Xpress to meet
            any of your demands. We offer unrivaled local service throughout
            South Florida, although we have the experience and equipment to
            handle long haul shipments with expediated services as well. We
            provide transloading services within our network to make sure your
            cargo gets where it needs to go, as well as door-to-door delivery
            with tracking available so you can verify delivery of sensitive
            freight. We also offer other services, including:
            <ul>
              <li>Transportation logistics</li>
              <li>Heavy haul operations</li>
              <li>Dedicated fleets</li>
            </ul>
            At Highlander Xpress, we specialize in expedited team service,
            providing the fastest delivery times for time-sensitive shipments.
            We'll also help you develop a plan to control your fuel costs, no
            matter the size of your shipment with us.
          </p>
          <h3>The Highlander Xpress Promise</h3>
          <p>
            We understand the importance of safe and timely deliveries and make
            it our goal to give you accurate and understandable explanations
            about our charges and the services we provide. We use only the best,
            well trained drivers and staff to handle your freight and provide
            24-hour dispatch to make sure your shipments reach their destination
            quickly. We use only new, well-maintained air rides and trailers to
            protect even the most fragile cargo and guarantee the safe delivery
            of your freight while it's in our hands. We take every precaution
            possible to minimize even the chance of damage during every single
            transportation. When you choose Highlander Xpress you can trust that
            your cargo will be well protected.
          </p>
        </div>
        <div className="content-right">
          <h2>Our Services</h2>
          <ul className="main-list">
            <li>Transportation logistics</li>
            <li>Heavy haul operations</li>
            <li>Dedicated fleets</li>
          </ul>
          <h3>States we are working at:</h3>
          <img src={map} width="260" height="160" alt="map" />
        </div>
      </div>
    </div>
  );
}

export default About;

import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import logo from "../assets/logo.png";
import phone from "../assets/name_phone.png";
import map from "../assets/map.png";

function Services() {
  return (
    <div className="services">
      <div className="content">
        <div className="content-left">
          <h2>Services</h2>
          <p>
            Operating throughout the continental United States, Highlander
            Xpress offers a complete range of services designed to get your
            time-sensitive freight to its destination as quickly and safely as
            possible.
            <br></br>
            <br></br>
            Highlander Xpress offers the services below:
          </p>
          <h3>Dry Van services using our air-bed truck fleet</h3>
          <ul>
            <li>Short haul and local expedited services</li>
            <li>Long haul with expedited services</li>
            <li>Transloading</li>
            <li>Door-to-door delivery</li>
          </ul>
          <h3>Dedicated Delivery</h3>
          <ul>
            <li>
              Our dedicated fleet service is designed for supply chain needs,
              offering customized solutions and an economic transportation
              service. Choosing a dedicated fleet means predictable, consistent
              costs and helps your business avoid fluctuations in the market
              while reducing your overall costs. This service can also make sure
              your products are in the store at a specific time, which is
              especially important if you sell food or seasonal merchandise.
            </li>
          </ul>
          <h3>Heavy Hauls</h3>
          <ul>
            <li>
              Our heavy haul services are designed to be the most effective
              solution for weight-sensitive shipments and use unique trailer
              configurations.
            </li>
          </ul>
          <h3>Container Shipping</h3>
          <ul>
            <li>
              Highlander Xpress offers a range of container shipping services
              and options allowing you to ship coast-to-coast or locally. We
              offer transloading services and door-to-door delivery of freight
              containers and use only well-maintained 53" trailers and air-ride
              trucks to get your container to its destination on time.
            </li>
          </ul>
        </div>
        <div className="content-right">
          <h2>Our Promise to You</h2>
          <ul className="main-list">
            <li>
              Highlander Xpress understands the importance of timely and safe
              deliveries.
            </li>
            <br></br>
            <li>
              We provide you with accurate and clear explanations about our
              services and charges and use only the best, highly trained drivers
              and staff.
            </li>
            <br></br>
            <li>
              With 24-hour dispatch, we will make sure your shipment gets to its
              destination on time.
            </li>
            <br></br>
            <li>
              We transport your freight using new and well-maintained air rides
              and 53" trailers that protect even fragile cargo.
            </li>
            <br></br>
            <li>
              We guarantee the safety of your freight in our hands and strive to
              minimize every chance of damage during every transportation.
            </li>
            <br></br>
          </ul>
          <h3>States we are working at:</h3>
          <img src={map} width="260" height="160" alt="map" />
        </div>
      </div>
    </div>
  );
}

export default Services;

import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import logo from "../assets/logo.png";
import phone from "../assets/name_phone.png";
import map from "../assets/map.png";
import truck from "../assets/about.jpeg";

function Careers() {
  return (
    <div className="careers">
      <div className="content">
        <div className="content-left">
          <h2>Careers</h2>
        </div>
        <div className="content-right"></div>
      </div>
    </div>
  );
}

export default Careers;

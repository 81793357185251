import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import logo from "../assets/logo.png";
import phone from "../assets/name_phone.png";
import map from "../assets/map.png";
import truck from "../assets/about.jpeg";
import ref from "../assets/Highlander_ Xpress_Trade_Ref.doc";
import serv from "../assets/Highlander_full_service_letter.docx";

function Resources() {
  return (
    <div className="resources">
      <div className="content">
        <div className="content-left">
          <h2>Resources</h2>
          <ul>
            <li className="footer-links">
              <a href={ref} download="Highlander_Xpress_Trade_Ref.doc">
                Highlander Xpress Trade References
              </a>
            </li>
            <br></br>
            <li className="footer-links">
              <a href={serv} download="Highlander_full_service_letter.docx">
                Highlander Xpress Full Service Letter
              </a>
            </li>
          </ul>
        </div>
        <div className="content-right"></div>
      </div>
    </div>
  );
}

export default Resources;

import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import logo from "../assets/logo.png";
import phone from "../assets/name_phone.png";
import map from "../assets/map.png";
import truck from "../assets/about.jpeg";

function Contact() {
  return (
    <div className="contact">
      <div className="content">
        <div className="content-left">
          <h2>Contact Us</h2>
          <table>
            <tbody>
              <tr>
                <td valign="top">
                  <b>Address:</b>
                </td>
                <td>
                  Highlander Xpress, Inc
                  <br></br>7100 NW 77th Ct
                  <br></br>
                  Miami, Fl 33166
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>Phone:</b>
                </td>
                <td>(786) 336-0601</td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>Fax:</b>
                </td>
                <td>(305) 513-9560</td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <b>e-mail:</b>
                </td>
                <td>info@highlanderxpress.com</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="content-right">
          <div className="google-map-code">
            <iframe
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyB2Xp6_JlUYIXsdGF6TEX-1WiERqjTMTqk&q=Highlander+Xpress,Miami+Florida"
              width="600"
              height="450"
              frameborder="0"
              style={{ border: 0 }}
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
              title="map"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;

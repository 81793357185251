import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import logo from "../assets/logo.png";
import phone from "../assets/name_phone.png";

function Footer() {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <div className="footer">
      <div className="copy">
        <Link to="/" className="image-container">
          <img src={logo} width="100%" height="100%" alt="logo" />
        </Link>
        <p>Copyright © {year} Highlander Xpress Inc.</p>
      </div>
      <div className="footer-links">
        <Link to="/about">
          <h4>About Us</h4>
        </Link>
        <Link to="/services">
          <h4>Our Services</h4>
        </Link>
        {/* <Link to="/careers">
          <h4>Careers</h4>
        </Link> */}
      </div>
      <div className="footer-links">
        <Link to="/contact">
          <h4>Contact Us</h4>
        </Link>
        <Link to="/resources">
          <h4>Resources</h4>
        </Link>
      </div>
    </div>
  );
}

export default Footer;
